import React, { useEffect, useState, useRef } from 'react';
import AgentCard from './AgentCard';
import SignUpForm from './SignUpForm';
import LegalAssistantPage from './LegalAssistantPage'; // Import the new page

const FreeTrialPromo: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const signUpFormRef = useRef<HTMLDivElement | null>(null); // Ref for the sign-up form

  useEffect(() => {
    const email = localStorage.getItem('email'); // Check if the user is logged in by checking localStorage
    if (email) {
      setIsLoggedIn(true); // If email exists, set the logged-in state to true
    }
  }, []);

  const scrollToSignUp = () => {
    if (signUpFormRef.current) {
      signUpFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const agents = [
    { name: 'Abigail', role: 'Research Agent', description: 'Provides comprehensive research, saving hours of legal work.', avatar: '/images/avatars_abigail.png' },
    { name: 'Jackson', role: 'Analyst Agent', description: 'Analyzes data and trends to provide legal insights.', avatar: '/images/avatars_jackson.png' },
    { name: 'Sophia', role: 'Drafter Agent', description: 'Drafts documents quickly and efficiently.', avatar: '/images/avatars_sophia.png' },
    { name: 'Mason', role: 'Compliance Agent', description: 'Ensures all legal processes meet compliance standards.', avatar: '/images/avatars_mason.png' },
    { name: 'Ava', role: 'Chain of Thought Agent', description: 'Organizes workflows for all other agents.', avatar: '/images/avatars_ava.png' },
    { name: 'Kari', role: 'Question Generation Agent', description: 'Generates relevant questions to aid decision-making.', avatar: '/images/avatars_kari.png' },
  ];

  // If the user is logged in, show the LegalServicesPage
  if (isLoggedIn) {
    return <LegalAssistantPage />;
  }

  // If not logged in, show the trial signup page
  return (
    <section className="services-page pt-24">
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center mb-6">Start Your 30-Day Free Trial</h1>
        <p className="text-center text-lg text-gray-500 mb-12">
          Unlock the power of AI agents for your law firm. Purchase one or more AI agents to act as your contracted staff. 
          Each agent reduces your workload by up to 75%, allowing your firm to focus on what matters most.
        </p>

        {/* Button to scroll down to the sign-up form */}
        <div className="text-center mb-12">
          <button 
            onClick={scrollToSignUp} 
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
          >
            Click Here to Sign Up for Free Trial
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {agents.map((agent, index) => (
            <AgentCard key={index} name={agent.name} role={agent.role} description={agent.description} avatar={agent.avatar} />
          ))}
        </div>

        {/* Sign-Up Form container */}
        <div className="text-center py-8 mt-12" ref={signUpFormRef}> {/* Ref added here */}
          <h2 className="text-3xl font-semibold mb-6">Ready to Get Started?</h2>
          <p className="text-gray-500 mb-6">Sign up today for your 30-day free trial and experience the future of legal support.</p>

          {/* Enlarged form, removed background */}
          <div className="p-8 max-w-2xl mx-auto">
            <SignUpForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FreeTrialPromo;
