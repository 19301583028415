import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { marked } from 'marked';
import { jsPDF } from 'jspdf';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { ensureValidToken } from '../services/tokenService';
import HeaderSection from './HeaderSection'; // Import the HeaderSection component

//const WEB_HOST = process.env.REACT_APP_WEB_HOST;

const HeaderSectionPage: React.FC = () => {
  const [sessionId, setSessionId] = useState<string | null>(null);  
  const navigate = useNavigate();

  // Generate a unique session ID
  useEffect(() => {
    let storedSessionId = localStorage.getItem('session_id');
    if (!storedSessionId) {
      storedSessionId = generateUUID();
      localStorage.setItem('session_id', storedSessionId);
    }
    setSessionId(storedSessionId);
  }, []);

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }; 

return (
    <div>
      {/* Insert the HeaderSection component above the left and right panes */}
      <HeaderSection />      
    </div>
  );
};

export default HeaderSectionPage;
