import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '../ConfigContext';

//const WEB_HOST = process.env.REACT_APP_WSS_HOST_DEV;

interface LoginPageProps {
  setEmail: (email: string | null) => void;  // Accept setEmail prop from Header
}

const LoginPage: React.FC<LoginPageProps> = ({ setEmail }) => {
  const { apiUrl } = useConfig();
  const [email, setEmailInput] = useState<string>('');  // renamed to avoid conflicts
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

   // Create a flag to track if the component is mounted
  useEffect(() => {
    let isMounted = true;

    return () => {
      isMounted = false;
    };
  }, []);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`${apiUrl}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const result = await response.json();
         // Store access token, refresh token, and other data
         localStorage.setItem('access_token', result.access_token);  // Store access token
         localStorage.setItem('refresh_token', result.refresh_token);  // Store refresh token
         localStorage.setItem('email', email);
         localStorage.setItem('user_id', result.user_id);
         localStorage.setItem('company_id', result.company_id);
         
        setEmail(email);  // Update the Header's email immediately
        navigate('/');  // Redirect to home page after successful login
      } else {
        setError('Invalid email or password');
      }
    } catch (error) {
      setError('Network error. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="max-w-md mx-auto mt-32 bg-gray-900 p-8 rounded-lg shadow-lg text-white">
      <h2 className="text-3xl font-bold mb-6 text-center">Login</h2>
      <form onSubmit={handleLogin}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-cyan-400 mb-2">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmailInput(e.target.value)}
            required
            className="w-full px-3 py-2 bg-gray-800 text-white rounded focus:border-cyan-500 focus:ring focus:ring-cyan-200 transition-shadow"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block text-cyan-400 mb-2">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-3 py-2 bg-gray-800 text-white rounded focus:border-cyan-500 focus:ring focus:ring-cyan-200 transition-shadow"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-cyan-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-cyan-400 transition-colors shadow-lg focus:outline-none focus:ring focus:ring-cyan-300"
          disabled={isLoading}
        >
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
        {error && <p className="mt-4 text-center text-red-500">{error}</p>}
      </form>
    </div>
  );
};

export default LoginPage;
