import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

export function isTokenExpired(token) {
    try {
        console.log('Decoding token:', token);  // Log the token before decoding
        const decoded = jwtDecode(token);  // Ensure jwtDecode is working as expected
        console.log('Decoded Token:', decoded);  // Log the decoded token

        const currentTime = Date.now() / 1000;  // Get current time in seconds
        console.log('Current Time:', currentTime, 'Token Expiry Time:', decoded.exp);

        return decoded.exp < currentTime;
    } catch (error) {
        console.error('Error decoding token:', error);  // Log any decoding errors
        return true;  // If there's an error, treat the token as expired
    }
}


export async function refreshToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
        // If no refresh token, user needs to log in again
        window.location.href = '/login';
        return;
    }

    try {
        const response = await axios.post('/api/token/refresh', {
            refresh_token: refreshToken
        });
        const newAccessToken = response.data.access_token;

        // Update localStorage with the new access token
        localStorage.setItem('access_token', newAccessToken);
    } catch (error) {
        console.error('Failed to refresh token:', error);
        window.location.href = '/login'; // Redirect to login on failure
    }
}

export async function ensureValidToken() {
    const accessToken = localStorage.getItem('access_token');
    
    console.log('Access Token:', accessToken);  // Log the token for debugging
    if (accessToken && isTokenExpired(accessToken)) {
        console.log('Token expired, refreshing...');
        await refreshToken();
    } else {
        console.log('Token is valid.');
    }
}

