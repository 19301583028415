import React from 'react';

const HeaderSection: React.FC = () => {
  return (
    <div className="bg-gray-800 p-4 rounded-lg mb-6 pt-16">
      <h2 className="text-2xl font-bold text-cyan-500 mb-4">Legal Services</h2>
      <div className="flex space-x-4">
        <button
          className="bg-cyan-500 text-white px-4 py-2 rounded-lg hover:bg-cyan-400"
          title="Ask your legal assistant any question, and the AI agents will assist you."
          onClick={() => window.location.href = '/services/legal/assistant'}
        >
          Legal Assistant
        </button>
        <button
          className="bg-cyan-500 text-white px-4 py-2 rounded-lg hover:bg-cyan-400"
          title="Generate a will with our AI agents."
          onClick={() => window.location.href = '/services/legal/will-generation'}
        >
          Will Generation
        </button>
      </div>
    </div>
  );
};

export default HeaderSection;
