import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ConfigProvider } from './ConfigContext';
import Header from './components/Header';
import HomePage from './components/HomePage';
import LegalAssistantPage from './components/LegalAssistantPage';  // Legal Assistant component
import WillDraftingPage from './components/WillDraftingPage';      // Will Drafting component
import LoginPage from './components/LoginPage';                    // Login page component
import FreeTrialPromo from './components/FreeTrialPromo';          // Free Trial Promo component
import Dashboard from './components/BlankDashboard';

const AppRouter: React.FC = () => {
  const [email, setEmail] = useState<string | null>(() => localStorage.getItem('email'));

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail); // Set the email if it exists in localStorage
    }
  }, []);


  return (
    <ConfigProvider>
      <Router>
      <Header email={email} setEmail={() => {}} />  {/* Header now handles email */}
      <Routes>
        {/* Default routes */}
        <Route path="/" element={<HomePage />} />
        {/*<Route path="/services/legal" element={<ServicesPage />} />}

        {/* Blank Dashboard */}
        <Route path="/services/legal" element={
          email ? <Dashboard /> : <Navigate to="/free-trial-promo" replace /> 
        } />

        {/* Legal Assistant Route */}
        <Route path="/services/legal/assistant" element={
          email ? <LegalAssistantPage /> : <Navigate to="/free-trial-promo" replace /> 
        } />

        {/* Will Drafting Route */}
        <Route path="/services/legal/will-generation" element={
          email ? <WillDraftingPage /> : <Navigate to="/free-trial-promo" replace />
        } />

        {/* Free Trial Promo page for non-logged-in users */}
        <Route path="/free-trial-promo" element={<FreeTrialPromo />} />

        {/* Login Page */}
        <Route path="/login" element={<LoginPage setEmail={setEmail} />} />

        {/* Catch-all route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
    </ConfigProvider>    
  );
};

export default AppRouter;
