// src/index.tsx
import React from "react";
import ReactDOM from "react-dom";
//import App from "./App";
import AppRouter from './AppRouter';
import "./styles/main.css"; // Import global CSS

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById("root")
);
