import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  email: string | null;
  setEmail: (email: string | null) => void;
}

const Header: React.FC<HeaderProps> = ({ email, setEmail }) => {
  const [isOpen, setIsOpen] = useState(false); // Mobile menu state
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false); // Services dropdown state
  const navigate = useNavigate();

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, [setEmail]);

  const handleLogout = () => {
    localStorage.clear();
    setEmail(null);
    navigate('/login');
  };

  const handleLegalServicesClick = () => {
    if (!email) {
      // If user is NOT logged in, navigate to Free Trial promo
      navigate('/free-trial-promo');
    } else {
      // If user is logged in, you can navigate to a different page if required
      navigate('/services/legal');
    }
    setIsServicesDropdownOpen(false);
  };

  return (
    <header className="fixed top-0 w-full bg-black bg-opacity-50 backdrop-filter backdrop-blur-md z-50">
      <nav className="container mx-auto px-6 py-3">
        <div className="flex justify-between items-center">
          <a href="/" className="text-2xl font-bold text-white">
            beyondrobots<span className="text-cyan-400">.ai</span>
          </a>
          <div className="hidden md:flex space-x-4">
            <NavLink href="/chat">Chat</NavLink>

            {/* Services Drop-down */}
            <div className="relative">
              <button
                onClick={() => setIsServicesDropdownOpen(!isServicesDropdownOpen)}
                className="block text-white hover:text-cyan-400 transition-colors focus:outline-none"
              >
                Services
              </button>
              {isServicesDropdownOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-gray-800 rounded shadow-lg">
                  <button
                    onClick={handleLegalServicesClick}
                    className="block w-full text-left px-4 py-2 text-white hover:bg-gray-700 transition-colors"
                  >
                    Legal Services
                  </button>
                </div>
              )}
            </div>

            <NavLink href="/about">About Us</NavLink>
            <NavLink href="/contact">Contact</NavLink>

            {/* User Dropdown */}
            {email ? (
              <div className="relative">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="text-white hover:text-cyan-400 transition-colors focus:outline-none"
                >
                  {email}
                </button>
                {isOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded shadow-lg">
                    <button
                      onClick={handleLogout}
                      className="block px-4 py-2 text-white hover:bg-gray-700 transition-colors w-full text-left"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <NavLink href="/login">Login</NavLink>
            )}
          </div>
          <button
            className="md:hidden text-white focus:outline-none"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? "✕" : "☰"}
          </button>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden mt-4 space-y-2">
            <NavLink href="/chat" onClick={() => setIsOpen(false)}>Chat</NavLink>
            <button
              onClick={() => {
                handleLegalServicesClick();
                setIsOpen(false);
              }}
              className="block text-white hover:text-cyan-400 transition-colors"
            >
              Legal Services
            </button>
            <NavLink href="/about" onClick={() => setIsOpen(false)}>About Us</NavLink>
            <NavLink href="/contact" onClick={() => setIsOpen(false)}>Contact</NavLink>
            {email ? (
              <button onClick={handleLogout} className="block w-full text-left text-white hover:text-cyan-400">
                Logout
              </button>
            ) : (
              <NavLink href="/login" onClick={() => setIsOpen(false)}>Login</NavLink>
            )}
          </div>
        )}
      </nav>
    </header>
  );
};

const NavLink: React.FC<{ href: string; onClick?: () => void; children: React.ReactNode }> = ({ href, onClick, children }) => (
  <a href={href} className="block text-white hover:text-cyan-400 transition-colors" onClick={onClick}>
    {children}
  </a>
);

export default Header;
