// src/config.js
export const getConfig = () => {
    const hostname = window.location.hostname;
    console.log("Hostname detected:", hostname);

    let config = {
      apiUrl: process.env.REACT_APP_API_HOST_PROD,
      wssUrl: process.env.REACT_APP_WSS_HOST_PROD,
    };
  
//    if (hostname === 'lamp-ubuntu-s-1vcpu-1gb-nyc1-01') {
    if (hostname === 'beyondrobots.ai') {
      console.log("Applying lamp-ubuntu config"); 
      config = {        
        wssUrl: process.env.REACT_APP_WSS_HOST_PROD,
        apiUrl: process.env.REACT_APP_API_HOST_PROD,
      };
    } else {
      console.log("Applying development config");
      config = {
        wssUrl: process.env.REACT_APP_WSS_HOST_DEV,
        apiUrl: process.env.REACT_APP_API_HOST_DEV,
      };
    }
  
    console.log("Config being returned: ", config);
    return config;
  };
  
