import React, { useState, useEffect } from "react";
import * as THREE from "three";  // Importing Three.js

const HomePage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);  // State to handle modal open/close

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    
    const heroElement = document.getElementById('hero-3d');
    if (!heroElement) return;

    heroElement.appendChild(renderer.domElement);

    const geometry = new THREE.TorusKnotGeometry(10, 3, 100, 16);
    const material = new THREE.MeshBasicMaterial({ color: 0x00ffff, wireframe: true });
    const torusKnot = new THREE.Mesh(geometry, material);
    scene.add(torusKnot);

    camera.position.z = 30;

    const animate = () => {
      requestAnimationFrame(animate);
      torusKnot.rotation.x += 0.01;
      torusKnot.rotation.y += 0.01;
      renderer.render(scene, camera);
    };

    animate();

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (heroElement) {
        heroElement.removeChild(renderer.domElement);
      }
    };
  }, []);

  return (
    <section className="relative h-screen flex items-center justify-center px-4 overflow-x-hidden">
      <div id="hero-3d" className="absolute top-0 left-0 w-full h-full z-0"></div>
      <div className="absolute z-10 w-full flex flex-col items-center justify-center h-full">
        <h1 className="text-4xl md:text-6xl font-bold mb-4 glow text-center">
          Beyond Robots. Beyond Innovation.
        </h1>
        <p className="text-2xl md:text-2xl font-bold mb-4 glow text-center">
          Embrace the future of superintelligent automation.
        </p>
        <button
          className="bg-cyan-500 text-white px-6 py-3 rounded-full text-lg font-semibold"
          onClick={() => setIsModalOpen(true)}  // Opens modal on click
        >
          Discover More
        </button>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => setIsModalOpen(false)}  // Close modal on clicking outside
        >
          <div
            className="bg-gray-900 p-8 rounded-lg max-w-5xl w-full max-h-screen overflow-y-auto"
            onClick={(e) => e.stopPropagation()}  // Prevent modal from closing when clicking inside
          >
            <h2 className="text-2xl font-bold mb-4">Discover More</h2>
            <p>
              Welcome to Beyond Robots, where we believe that the fusion of advanced Artificial Intelligence and robotics can transform the way businesses operate, leading us into a new era of abundance and freedom.
            </p>
            <p className="mt-4">
              Artificial Intelligence (AI) is not just a buzzword—it's a revolutionary technology that's reshaping industries across the globe. At its core, AI is about creating systems that can perform tasks that typically require human intelligence. This includes learning from data (machine learning), understanding natural language, recognizing patterns, and making complex decisions.
            </p>
            <p className="mt-4">
              Advancements in AI have accelerated exponentially in recent years. From deep learning algorithms that can diagnose diseases with greater accuracy than doctors, to AI models that can predict market trends with unprecedented precision, the possibilities are endless. These technologies are becoming more accessible and are no longer confined to tech giants or research labs.
            </p>
            <p className="mt-4">
              For businesses, this means unlocking new levels of efficiency, productivity, and innovation. AI can automate repetitive tasks, freeing up your team to focus on strategic initiatives. It can provide insights from vast amounts of data, helping you make informed decisions faster. AI-powered chatbots can enhance customer service, while predictive analytics can optimize supply chains.
            </p>
            <p className="mt-4">
              Robotics, when integrated with AI, takes automation to the next level. Robots can perform physical tasks with precision and consistency, operating around the clock without fatigue. When these robots are guided by AI, they become adaptable and intelligent, capable of learning and improving over time.
            </p>
            <p className="mt-4">
              Imagine a manufacturing line where robots not only assemble products but also detect defects in real-time and adjust their processes accordingly. Picture an agricultural system where AI-driven robots tend to crops, optimizing water usage and maximizing yields. Envision warehouses where intelligent robots manage inventory autonomously, reducing errors and operational costs.
            </p>
            <p className="mt-4">
              Together, AI and robotics build resilience and abundance. They create systems that are robust against disruptions, whether from market fluctuations or global events. Automation ensures that essential processes continue smoothly, while AI provides the agility to adapt to new challenges.
            </p>
            <p className="mt-4">
              At Beyond Robots, our mission is to bring these advanced technologies to businesses of all sizes. We believe that when automation becomes widespread, it not only boosts individual businesses but also contributes to a thriving economy where resources are utilized efficiently, and opportunities are abundant.
            </p>
            <p className="mt-4">
              Together, we can cultivate a garden of abundance and freedom. By embracing AI and robotics, we're not just automating tasks—we're freeing human potential. Your team can focus on creativity, innovation, and human-centric activities that machines cannot replicate. This shift opens doors to new possibilities, new markets, and new ways of thinking.
            </p>
            <p className="mt-4">
              We invite you to join us on this exciting journey. Let's explore how we can tailor AI and robotic solutions to fit your unique needs. Let's push the boundaries of what's possible and build a future where technology empowers us all.
            </p>
            <p className="mt-4">
              The future is not just about technology—it's about what technology can enable for humanity. Together, let's wonder, innovate, and create a world where abundance is the norm, and freedom is realized through automation.
            </p>
            
            {/* Add more modal content here as needed */}
            <button
              className="mt-4 bg-cyan-500 text-white px-4 py-2 rounded"
              onClick={() => setIsModalOpen(false)}  // Close modal on button click
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default HomePage;
