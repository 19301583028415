import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown'; // Import react-markdown
import HeaderSection from './HeaderSection';
import { useConfig } from '../ConfigContext';

//const APP_HOST = process.env.REACT_APP_WSS_HOST_PROD;

const LegalAssistantPage: React.FC = () => {
  const { wssUrl } = useConfig();
  const [messages, setMessages] = useState<any[]>([]);
  const [inputMessage, setInputMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
  const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>({});
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);

  const avatars = {
    clarification: '/images/avatars_ava.png',
    research_question: '/images/avatars_kari.png',
    research_result: '/images/avatars_abigail.png',
    thinking: '/images/avatars_ava.png',
    analysis: '/images/avatars_jackson.png',
    report: '/images/avatars_sophia.png',
    compliance_feedback: '/images/avatars_mason.png',
    user: '/images/default_user_avatar.png' // Default avatar for the user
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    let storedSessionId = localStorage.getItem('session_id');
    const storedEmail = localStorage.getItem('email'); // Get user email from local storage
    const storedUserId = localStorage.getItem('user_id'); // Get user_id from local storage
    const storedCompanyId = localStorage.getItem('company_id'); // Get company_id from local storage

    if (!storedSessionId) {
      storedSessionId = generateUUID();
      localStorage.setItem('session_id', storedSessionId);
    }
    setSessionId(storedSessionId);
    setUserEmail(storedEmail); // Set user email in state

    console.log('WebSocket URL: ', wssUrl);
    const ws = new WebSocket(`${wssUrl}/ws/legal_services/legal_assistant/${storedSessionId}`);
    console.log('Opening websocket: ', `${wssUrl}/ws/legal_services/legal_assistant/${storedSessionId}`);
    ws.onopen = () => {
      console.log('WebSocket connection opened');
      setWebSocket(ws);

      // Send user_id and company_id once the WebSocket connection is open
      const sessionData = {
        session_id: storedSessionId,
        user_id: storedUserId,
        company_id: storedCompanyId,
      };
      ws.send(JSON.stringify(sessionData));  // Send the session data to the server
    };

    ws.onmessage = (event) => {
      const messageData = JSON.parse(event.data);
      if (messageData.sender !== 'user') {
        handleIncomingMessage(messageData);
      }
    };
    
    ws.onclose = () => {
      console.log('WebSocket connection closed');
      setWebSocket(null);
    };

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [wssUrl]);

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const handleIncomingMessage = (messageData: any) => {
    console.log('Processing incoming message:', messageData);

    if (messageData.type === 'research_result') {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          title: messageData.title,
          url: messageData.url,
          type: messageData.type,
          sender: messageData.sender
        }
      ]);
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: messageData.content,
          type: messageData.type,
          sender: messageData.sender
        }
      ]);
    }

    setIsLoading(false);
  };

  const groupedMessages = messages
    .filter((message) => message.sender !== 'user') // Exclude user messages from being grouped
    .reduce((acc: any, message) => {
      if (!acc[message.type]) {
        acc[message.type] = [];
      }
      acc[message.type].push(message);
      return acc;
    }, {});

  const handleSendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    if (inputMessage.trim() !== '') {
      const userMessage = { text: inputMessage, sender: 'user', email: userEmail || 'User' };
      setMessages((prevMessages) => [...prevMessages, userMessage]); // Display the user's message immediately

      setInputMessage('');

      if (webSocket && webSocket.readyState === WebSocket.OPEN) {
        const messageToSend = {
          type: messages.length === 0 ? 'new_question' : 'followup',
          content: inputMessage,
          session_id: sessionId,
          sender: 'user'
        };
        webSocket.send(JSON.stringify(messageToSend));
        setIsLoading(true);
      } else {
        console.error('WebSocket connection not open!');
      }
    }
  };

  // Toggle expanded/collapsed sections
  const toggleSection = (messageType: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [messageType]: !prevState[messageType] // Toggle the current state
    }));
  };

  return (
    <div className="h-screen">
      <div className="w-full"> {/* Ensure full width for header section */}
        <HeaderSection />
      </div>
      <div className="container mx-auto p-4">
        <div className="flex flex-col">
          {Object.keys(groupedMessages).map((messageType) => (
            <div key={messageType} className="mb-4">
              <div
                className="flex items-center cursor-pointer bg-gray-800 p-4 rounded-lg hover:bg-gray-700"
                onClick={() => toggleSection(messageType)} // Toggle expand/collapse
              >
                <img
                  src={avatars[messageType] || avatars['user']} // Default to user avatar if no type found
                  alt={messageType}
                  className="w-8 h-8 rounded-full mr-4"
                />
                <h3 className="text-xl font-bold text-cyan-500 capitalize">
                  {messageType.replace('_', ' ')}
                </h3>
              </div>
              {expandedSections[messageType] && (
                <div className="ml-12 mt-2 response-container">
                  {groupedMessages[messageType].map((msg: any, index: number) => (
                    <div key={index} className="mb-2">
                      {messageType === 'research_result' ? (
                        <div className="p-2 bg-gray-700 rounded-lg text-white">
                          {/* Render research result with clickable link */}
                          <a href={msg.url} target="_blank" rel="noopener noreferrer" className="text-cyan-400 hover:text-cyan-300">
                            {msg.title}
                          </a>
                        </div>
                      ) : (
                        <div className="p-2 bg-gray-700 rounded-lg text-white">
                          {/* Render normal text if not research_result */}
                          <ReactMarkdown>{msg.text}</ReactMarkdown>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

          {/* Render user messages separately */}
          <div className="mb-4">
            {messages
              .filter((msg) => msg.sender === 'user')
              .map((userMsg, index) => (
                <div key={index} className="flex items-center mb-2">
                  <img
                    src={avatars['user']}
                    alt="User"
                    className="w-8 h-8 rounded-full mr-4"
                  />
                  <div className="bg-gray-700 p-2 rounded-lg text-white">
                    <strong>{userEmail || 'User'}:</strong> {userMsg.text}
                  </div>
                </div>
              ))}
          </div>
        </div>

        <form onSubmit={handleSendMessage} className="mt-4">
          <div className="flex items-center">
            <textarea
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              className="w-full p-4 bg-gray-700 text-white rounded-l-lg focus:outline-none"
              rows={4}
              placeholder="Ask your legal question here..."
              disabled={isLoading}
            />
            <button
              type="submit"
              className="bg-cyan-500 text-white p-4 rounded-r-lg hover:bg-cyan-400"
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'Send'}
            </button>
          </div>
        </form>

        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default LegalAssistantPage;
