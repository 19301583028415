import React, { useState } from 'react';
import { useConfig } from '../ConfigContext';

//const WEB_HOST = process.env.REACT_APP_WEB_HOST;

const SignUpForm: React.FC = () => {
  const { apiUrl } = useConfig();
  const [status, setStatus] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus('');

    const form = e.currentTarget;
    const formData = new FormData(form);

    const email = formData.get('email') as string;
    const domain = email.split('@')[1]; // Extract domain from the email

    const data = {
      full_name: `${formData.get('firstName')} ${formData.get('lastName')}`, // Combine first and last names into full_name
      email: email,
      password: formData.get('password'), // New password field
      company_name: domain, // Assign the domain part of the email as the company name
      phone: "", // Leave phone empty for now
      address: "", // Leave address empty for now
    };

    try {
      const response = await fetch(`${apiUrl}/api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setStatus('You have successfully signed up for the free trial!');
        form.reset();
      } else {
        const result = await response.json();
        setStatus(`Error: ${result.error || 'Something went wrong'}`);
      }
    } catch (error) {
      setStatus('Network error. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-gray-900 p-8 rounded-lg shadow-lg relative text-white">
      <h2 className="text-3xl font-bold mb-6 text-center glow">Sign Up for 30-Day Free Trial</h2>

      <div className="mb-4">
        <label htmlFor="firstName" className="block text-cyan-400 mb-2">First Name</label>
        <input 
          type="text" 
          id="firstName" 
          name="firstName" 
          required 
          className="w-full px-3 py-2 bg-gray-800 text-white rounded focus:border-cyan-500 focus:ring focus:ring-cyan-200 transition-shadow"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="lastName" className="block text-cyan-400 mb-2">Last Name</label>
        <input 
          type="text" 
          id="lastName" 
          name="lastName" 
          required 
          className="w-full px-3 py-2 bg-gray-800 text-white rounded focus:border-cyan-500 focus:ring focus:ring-cyan-200 transition-shadow"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="email" className="block text-cyan-400 mb-2">Email</label>
        <input 
          type="email" 
          id="email" 
          name="email" 
          required 
          className="w-full px-3 py-2 bg-gray-800 text-white rounded focus:border-cyan-500 focus:ring focus:ring-cyan-200 transition-shadow"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="password" className="block text-cyan-400 mb-2">Password</label>
        <input 
          type="password" 
          id="password" 
          name="password" 
          required 
          className="w-full px-3 py-2 bg-gray-800 text-white rounded focus:border-cyan-500 focus:ring focus:ring-cyan-200 transition-shadow"
        />
      </div>

      <button 
        type="submit" 
        className="w-full bg-cyan-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-cyan-400 transition-colors shadow-lg focus:outline-none focus:ring focus:ring-cyan-300"
        disabled={isLoading}
      >
        {isLoading ? 'Submitting...' : 'Start Free Trial'}
      </button>
      {status && <p className="mt-4 text-center text-cyan-400">{status}</p>}
    </form>
  );
};

export default SignUpForm;
