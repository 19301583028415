import React, { createContext, useContext, ReactNode } from 'react';
import { getConfig } from './config';

// Define the type for the props that the ConfigProvider will accept
interface ConfigProviderProps {
  children: ReactNode;  // 'children' prop should be of type ReactNode
}

// Create the ConfigContext
const ConfigContext = createContext(null);

// Export a provider component
export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const config = getConfig();  // Get the config once

  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  );
};

// Export a hook to use the config easily in components
export const useConfig = () => useContext(ConfigContext);
