import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { marked } from 'marked';
import { jsPDF } from 'jspdf';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { ensureValidToken } from '../services/tokenService';
import HeaderSection from './HeaderSection'; // Import the HeaderSection component
import { useConfig } from '../ConfigContext';

//const WEB_HOST = process.env.REACT_APP_WEB_HOST;

const WillDraftingPage: React.FC = () => {
  const { apiUrl } = useConfig();
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [willContent, setWillContent] = useState<string>('');
  const [renderedContent, setRenderedContent] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusMessages, setStatusMessages] = useState<{ message: string, isError: boolean }[]>([]);
  const navigate = useNavigate();

  // Generate a unique session ID
  useEffect(() => {
    let storedSessionId = localStorage.getItem('session_id');
    if (!storedSessionId) {
      storedSessionId = generateUUID();
      localStorage.setItem('session_id', storedSessionId);
    }
    setSessionId(storedSessionId);
  }, []);

  useEffect(() => {
    if (willContent) {
      const processContent = async () => {
        const result = await marked(willContent);
        setRenderedContent(result);  // Set the rendered content as a string
      };

      processContent();
    }
  }, [willContent]);

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadedFile(e.target.files[0]);
      setStatusMessages((prev) => [...prev, { message: 'File received, click Generate Will to start...', isError: false }]);
    }
  };

  const handleGenerateWill = async () => {
    if (!uploadedFile || !sessionId) {
      setStatusMessages((prev) => [...prev, { message: 'Please upload a notes file to proceed.', isError: true }]);
      return;
    }    
    
    const user_id = localStorage.getItem('user_id');
    const company_id = localStorage.getItem('company_id');

    if (!user_id || !company_id || !uploadedFile) {
      setStatusMessages((prev) => [...prev, { message: 'Please log in or upload a notes file to proceed.', isError: true }]);
      return;
    }

    setStatusMessages((prev) => [...prev, { message: 'Analyzing file...', isError: false }, { message: 'Generating will, give us a few minutes...', isError: false }]);

    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', uploadedFile);
    formData.append('user_id', user_id);
    formData.append('company_id', company_id);

    try {
      await ensureValidToken();

      const accessToken = localStorage.getItem('access_token');
      const response = await axios.post(`${apiUrl}/api/legal/will-drafting`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${accessToken}`,  // Pass the token in headers
          },
      });
       
       setWillContent(response.data.willContent);
       setStatusMessages((prev) => [...prev, { message: 'Will generated successfully!', isError: false }]); 

    } catch (err) {
      setStatusMessages((prev) => [...prev, { message: 'Failed to generate the will. Please try again.', isError: true }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleComplianceCheck = () => {
    setStatusMessages((prev) => [...prev, { message: 'This feature is coming soon...', isError: false }, { message: '', isError: false }]);
  };

  const saveAsPDF = () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    const marginTop = 10;
    const marginBottom = 10;
    const contentHeight = pageHeight - marginTop - marginBottom;
    if (willContent) {
        const lines = doc.splitTextToSize(willContent, 180); // Split content into lines that fit the page width
    
        let cursorY = marginTop;
    
        lines.forEach((line, index) => {
          if (cursorY + doc.getTextDimensions(line).h > contentHeight) {
            doc.addPage(); // Add a new page if the content overflows the current page
            cursorY = marginTop; // Reset cursor to the top of the new page
          }
    
          doc.text(line, 10, cursorY);
          cursorY += doc.getTextDimensions(line).h; // Move the cursor down for the next line
        });
        doc.save('generated_will.pdf');
    }
    setStatusMessages((prev) => [...prev, { message: 'Will saved as PDF', isError: false }]);
};  

const saveAsWord = () => {
  const doc = new Document({
    sections: [{
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: "LAST WILL & TESTAMENT OF",
              bold: true,
              size: 28,
            }),
            new TextRun({
              text: " CLIENT NAME",
              size: 28,
            })
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: willContent,  // Insert will content here
              size: 24
            })
          ]
        })
      ]
    }]
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, "generated_will.docx");
  });

  setStatusMessages((prev) => [...prev, { message: 'Will saved as Word Document', isError: false }]);
};

return (
    <div>
      {/* Insert the HeaderSection component above the left and right panes */}
      <HeaderSection />

      {/* Main Layout with Left Navigation and Right Content Areas */}
      <div className="flex h-screen pt-8">      
        {/* Left Navigation Panel */}
        <div className="w-1/4 bg-gray-900 p-6">
          <h2 className="text-xl font-bold text-white mb-4">Actions</h2>
          <div className="flex flex-col space-y-4">
            <button
              onClick={() => document.getElementById('fileInput')?.click()}
              className="bg-cyan-500 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-cyan-400 transition-colors"
            >
              Upload Notes File
            </button>
            <input
              id="fileInput"
              type="file"
              accept=".txt"
              className="hidden"
              onChange={handleFileUpload}
            />
            <button
              onClick={handleGenerateWill}
              className="bg-cyan-500 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-cyan-400 transition-colors"
              disabled={isLoading}
            >
              {isLoading ? 'Generating...' : 'Generate Will'}
            </button>
            <button
              onClick={handleComplianceCheck}
              className="bg-yellow-500 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-yellow-400 transition-colors"
            >
              Compliance Check
            </button>
            <button
              onClick={saveAsWord}
              className="bg-green-500 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-green-400 transition-colors"
            >
              Save as Word
            </button>
          </div>
        </div>

        {/* Right Content Area */}
        <div className="w-3/4 bg-gray-100 p-6">
          {/* Top: Display Generated Will */}
          <div className="w-full h-1/3 bg-white p-6 rounded-lg shadow-md overflow-auto">
            <h3 className="text-xl font-bold mb-4">Generated Will</h3>
            {willContent ? (
              <div style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: renderedContent || '' }} />
            ) : (
              <p>No will generated yet. Upload your notes file and click "Generate Will".</p>
            )}
          </div>

          {/* Bottom: Status Messages */}
          <div className="w-full h-1/3 bg-gray-800 text-white p-6 mt-4 rounded-lg overflow-auto">
            <h3 className="text-xl font-bold mb-4">Status</h3>
            <div className="space-y-2">
              {statusMessages.length > 0 ? (
                statusMessages.map((messageObj, index) => (
                  <p key={index} className={messageObj.isError ? 'text-red-500' : ''}>
                    {messageObj.message}
                  </p>
                ))
              ) : (
                <p>No status messages yet.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WillDraftingPage;
