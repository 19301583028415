import React from 'react';

interface AgentCardProps {
  name: string;
  role: string;
  description: string;
  avatar: string;
}

const AgentCard: React.FC<AgentCardProps> = ({ name, role, description, avatar }) => {
  return (
    <div className="agent-card bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-lg shadow-lg p-6 text-center hover:scale-105 transform transition-all">
      <img src={avatar} alt={`${name}'s Avatar`} className="w-24 h-24 mx-auto rounded-full mb-4" />
      <h3 className="text-xl font-bold text-cyan-400">{name}</h3>
      <h4 className="text-md text-gray-400">{role}</h4>
      <p className="text-gray-300 mt-4">{description}</p>
    </div>
  );
};

export default AgentCard;
